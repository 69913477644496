<template>
  <div class="vasion-flex vasion-calc-height">
    <router-view class="vasion-remaining-after-two-subnavs" />
  </div>
</template>

<script>

export default {
  name: 'TheHome',
  components: {
  },
  created() {
    if (this.$route.name === 'TheHome') {
      this.$store.dispatch('mainViews/toggleVasionHome', true)
      this.$router.push({ name: 'TheVasionHome' })
    }
  },
}
</script>

<style lang="scss">
</style>
